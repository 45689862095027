import { useState } from 'react';
import reportsService from '../../api/services/reports.service';
import { ErrorKey } from '../../track';
import { downloadBlob } from '../../utils/functions/download';

const useLastReport = () => {
  const [loading, setLoading] = useState(false);

  const getLastReport = (domain) => {
    setLoading(true);

    reportsService
      .getLastReport()
      .then((response) => {
        downloadBlob(response, `${domain}.pdf`);
      })
      .catch(() => {
        ErrorKey('errors.thereWasAnError', 'getReportPDF');
      })
      .finally(() => setLoading(false));
  };

  return {
    loading,
    getLastReport,
  };
};

export default useLastReport;
