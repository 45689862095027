import React, { useEffect } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import Tool4StepsTutorial from '../../components/organisms/Tool4StepsTutorial/Tool4StepsTutorial';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import InfoBanner from '../../components/molecules/InfoBanner/InfoBanner';
import SCSupplierSecuritySection from './SupplierSecuritySection.style';
import SupplierSecurityExplanationCard from '../../components/organisms/SupplierSecurityExplanationCard/SupplierSecurityExplanationCard';
import SupplierNameCard from '../../components/organisms/SupplierNameCard/SupplierNameCard';
import useMonitoredSuppliers from '../../hooks/monitoredSuppliers/useMonitoredSuppliers';
import MonitoredSuppliersGeneralVision from '../../components/organisms/MonitoredSuppliersGeneralVision/MonitoredSuppliersGeneralVision';
import SupplierAnalysisCard from '../../components/organisms/SupplierAnalysisCard/SupplierAnalysisCard';
import BackButton from '../../components/molecules/BackButton/BackButton';
import AnyDoubts from '../../components/molecules/AnyDoubts/AnyDoubts';
import SectionHeader from '../../components/molecules/SectionHeader/SectionHeader';

const SupplierSecuritySection = () => {
  const client = useSelector((redux) => redux.client);
  const { monitoredSuppliers } = useMonitoredSuppliers();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const i18n = useTranslation();

  useEffect(() => {
    if (client && !client?.finishedTutorials?.supplier) {
      dispatch(showPopUp('tutorial', 'supplier'));
    }
  }, []);

  const activeSupplier = monitoredSuppliers.find(
    (monitoredSupplier) => monitoredSupplier.domain === id
  );

  useEffect(() => {
    if (!activeSupplier) {
      navigate('/client/supplier-security');
    }
  }, [activeSupplier?.domain]);

  return (
    <SectionLayout>
      <SCSupplierSecuritySection>
        {!id && monitoredSuppliers && (
          <>
            <div className="section-organism">
              <SectionHeader />
            </div>

            <div className="section-organism">
              <Tool4StepsTutorial />
            </div>

            <div className="section-organism">
              <SupplierSecurityExplanationCard />
            </div>

            <div className="section-organism">
              <MonitoredSuppliersGeneralVision />
            </div>

            <AnyDoubts
              tutorialOnClick={() =>
                dispatch(showPopUp('tutorial', 'supplier'))
              }
            />
          </>
        )}

        {id && (
          <>
            {activeSupplier?.impersonation && (
              <>
                {/* Botón de vuelta */}
                <div className="section-back-button">
                  <BackButton />
                </div>

                {activeSupplier?.impersonation?.overall.score < 5 && (
                  // Banner de alerta de puntuación baja.
                  <div className="section-organism">
                    <InfoBanner
                      width="fit-content"
                      type="warn"
                      title={i18n.t(
                        'suppliersSecurity.supplierDetailPage.poorEmailSecurityTitle'
                      )}
                      text={[
                        i18n.t(
                          'suppliersSecurity.supplierDetailPage.poorEmailSecurityText'
                        ),
                      ]}
                      onClickButton={() =>
                        navigate('/client/phishingsimulator')
                      }
                      buttonText={i18n.t(
                        'suppliersSecurity.supplierDetailPage.openPhishingTraining'
                      )}
                    />
                  </div>
                )}

                {/* Nombre del proveedor */}
                <div className="section-organism">
                  <SupplierNameCard supplier={activeSupplier} />
                </div>

                {/* Análisis del proveedor */}
                <div className="section-organism">
                  <SupplierAnalysisCard supplier={activeSupplier} />
                </div>
              </>
            )}
          </>
        )}
      </SCSupplierSecuritySection>
    </SectionLayout>
  );
};

export default SupplierSecuritySection;
