import { firestore } from '../../firebase';

import { GET_MONITORED_SUPPLIERS } from './types';

const convertTimeStampToDate = (timestamp) => {
  const date = `${timestamp.toDate().getUTCDate()}/${
    timestamp.toDate().getUTCMonth() + 1
  }/${timestamp.toDate().getUTCFullYear()}`;
  return date;
};

export const setSupplierListener = async (client, dispatch) => {
  if (client.monitoredSuppliers) {
    const clientMonitoredSuppliers = Object.values(client.monitoredSuppliers);

    const monitoredSuppliers = clientMonitoredSuppliers;

    clientMonitoredSuppliers.forEach((item, index) => {
      firestore
        .collection('reports')
        .where('domain', '==', item.domain)
        .where('client', '==', null)
        .where('emailAt', '==', item.atEmail || item.domain)
        .orderBy('timestamp', 'desc')
        .limit(1)
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            const supplierReport = doc.data();

            monitoredSuppliers[index] = {
              ...monitoredSuppliers[index],
              ...supplierReport,
              date: convertTimeStampToDate(supplierReport.timestamp),
            };
          });

          dispatch({
            type: GET_MONITORED_SUPPLIERS,
            payload: [...monitoredSuppliers],
          });
        });
    });
  }
};

export default true;
