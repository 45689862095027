export const ERRORS = {
  DOMAIN_ALREADY_EXISTS: 'domain_already_exists',
  MEMBER_ALREADY_EXIST: 'member_already_exist',
  INVALID_DOMAIN: 'invalid_domain',
  CLIENT_ALREADY_EXIST: 'client_already_exist',
  INVALID_EMAIL: 'invalid_email',
  ALREADY_HAS_RENEWAL: 'already_has_renewal',
  CANNOT_ADD_YOUR_DOMAINS: 'cannot_add_your_domains',
  PROVIDER_ALREADY_EXISTS: 'provider_already_exists',
};
