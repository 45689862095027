import api from '../config';
import { REPORTS_API } from '../paths';

const getLastReport = () => {
  return api.get(`${REPORTS_API}/last`, {
    responseType: 'blob',
  });
};

const getSupplierReport = (domain) => {
  return api.get(`${REPORTS_API}/supplier`, {
    responseType: 'blob',
    params: { domain },
  });
};

const getWebReport = (domain) => {
  return api.get(`${REPORTS_API}/web`, {
    responseType: 'blob',
    params: { domain },
  });
};

export default { getLastReport, getSupplierReport, getWebReport };
