import React from 'react';

import {
  faDollyBox,
  faEnvelope,
  faFileInvoice,
  faGlobe,
  faMagnifyingGlassChart,
  faPaperPlaneTop,
  faPen,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import SCSupplierNameCard from './SupplierNameCard.style';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { getDateFormat } from '../../../utils/format/dataFormat';
import RadialChart from '../../molecules/RadialChart/RadialChart';
import Icon from '../../atoms/Icon/Icon';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import useSupplierReport from '../../../hooks/reports/useSupplierReport';

const SupplierNameCard = ({ supplier }) => {
  const i18n = useTranslation();
  const { name, domain, atEmail, timestamp } = supplier || {};
  const dispatch = useDispatch();

  const { getSupplierReport, loading: isDownloadingReport } =
    useSupplierReport();

  return (
    <SCSupplierNameCard>
      <SectionCard
        headerIcon={faDollyBox}
        headerTitle={i18n.t(
          'suppliersSecurity.supplierDetailPage.supplierName'
        )}>
        {supplier && (
          <div className="ss-supplier-summary">
            <h2>{name}</h2>
            <div className="ss-supplier-summary-content">
              <RadialChart score={supplier.overall.score} />
              <div className="ss-supplier-summary-info">
                <div className="ss-supplier-summary-info-content">
                  <div>
                    <Icon
                      iconName={faGlobe}
                      color="var(--light-grey-6)"
                      size="navIcon"
                    />
                    <div>
                      <h3>
                        {i18n.t(
                          'suppliersSecurity.supplierDetailPage.websiteDomain'
                        )}
                      </h3>
                      <span>{domain}</span>
                    </div>
                  </div>
                </div>
                <div className="ss-supplier-summary-info-content">
                  <div>
                    <Icon
                      iconName={faMagnifyingGlassChart}
                      color="var(--light-grey-6)"
                      size="navIcon"
                    />
                    <div>
                      <h3>
                        {i18n.t(
                          'suppliersSecurity.supplierDetailPage.lastScan'
                        )}
                      </h3>
                      <span>{getDateFormat(timestamp.toDate())}</span>
                    </div>
                  </div>
                </div>
                <div className="ss-supplier-summary-info-content">
                  <div>
                    <Icon
                      iconName={faEnvelope}
                      color="var(--light-grey-6)"
                      size="navIcon"
                    />
                    <div>
                      <h3>
                        {i18n.t(
                          'suppliersSecurity.supplierDetailPage.emailDomain'
                        )}
                      </h3>
                      <span>{atEmail}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Button
                icon={!isDownloadingReport && faFileInvoice}
                onClick={() => getSupplierReport(domain)}
                width="180px"
                text={
                  isDownloadingReport ? (
                    <SpinnerText text={i18n.t('misc.preparing')} />
                  ) : (
                    i18n.t('score.downloadReport')
                  )
                }
                size="mid"
                color="white"
                disabled={isDownloadingReport}
              />
              <Button
                icon={faPaperPlaneTop}
                width="180px"
                text={i18n.t('suppliersSecurity.notifySupplier.notify')}
                size="mid"
                color="white"
                onClick={() => {
                  dispatch(
                    showPopUp('SentEmailTo', {
                      title: i18n.t('suppliersSecurity.notifySupplier.notify'),
                      type: 'supplierSecurity',
                    })
                  );
                }}
              />
              <Button
                icon={faPen}
                onClick={() =>
                  dispatch(
                    showPopUp('editSupplier', {
                      domain: supplier.domain,
                      name: supplier.name,
                      atEmail: supplier.atEmail,
                    })
                  )
                }
                width="180px"
                text={i18n.t('suppliersSecurity.addEdit.editTitle')}
                size="mid"
                color="white"
              />
              <Button
                icon={faTrashCan}
                onClick={() =>
                  dispatch(showPopUp('removeSupplier', supplier.domain))
                }
                width="180px"
                text={i18n.t('suppliersSecurity.delete.title')}
                size="mid"
                color="white"
              />
            </div>
          </div>
        )}
      </SectionCard>
    </SCSupplierNameCard>
  );
};

export default SupplierNameCard;
