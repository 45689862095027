import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import { useTranslation } from '../../../i18n';
import { REGULAR_EXPRESSIONS } from '../../../utils/constants/regularExpressions';
import Button from '../../atoms/Button/Button';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { ERRORS } from '../../../utils/constants/errors';
import { ErrorKey } from '../../../track';
import clientsService from '../../../api/services/clients.service';

const SCAddSupplier = styled.div`
  .ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 6px !important;
  }

  .modal-buttons {
    margin-top: 40px;

    > .ant-form-item {
      margin-bottom: 10px;
    }
  }
`;

const AddSupplier = () => {
  const client = useSelector((redux) => redux.client);
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onValuesChange = (changedValues) => {
    if (typeof changedValues?.website === 'string') {
      form.setFieldValue('atEmail', changedValues?.website);
      form.validateFields(['atEmail']);
    }
  };

  const addSupplier = async ({ name, website, atEmail }) => {
    try {
      setLoading(true);
      await clientsService.addSupplier({
        name: name.trim(),
        domain: website,
        atEmail,
      });
      axios.post(`/actions/${client.id}/`, { type: 'add-suppliers' });
      dispatch(showPopUp(null));
    } catch (error) {
      const { error: errorCode } = error.response.data;

      const i18nKeys = {
        [ERRORS.CANNOT_ADD_YOUR_DOMAINS]: 'errors.cannotAddYourDomains',
        [ERRORS.PROVIDER_ALREADY_EXISTS]: 'errors.providerAlreadyExists',
      };

      const i18nKey = i18nKeys[errorCode] ?? 'errors.not_found';

      ErrorKey(i18nKey);
    } finally {
      setLoading(false);
    }
  };

  const isButtonDisabled = () => {
    const inputsAreEmpty = Object.values(form.getFieldsValue()).some(
      (data) => !data
    );

    const hasError = form.getFieldsError().some(({ errors }) => errors.length);

    return inputsAreEmpty || hasError || loading;
  };

  return (
    <ModalLayout>
      <SCAddSupplier>
        <h1>{i18n.t(`suppliersSecurity.addEdit.addTitle`)}</h1>

        <div className="modal-card-content">
          <Form
            form={form}
            onFinish={addSupplier}
            layout="vertical"
            onValuesChange={onValuesChange}>
            <Form.Item
              label={i18n.t(`suppliersSecurity.addEdit.name`)}
              name="name"
              rules={[
                {
                  required: true,
                  message: i18n.t('welcome.formErrors.common.required'),
                },
              ]}>
              <Input
                size="large"
                type="text"
                placeholder={i18n.t(`suppliersSecurity.addEdit.name`)}
              />
            </Form.Item>

            <Form.Item
              label={i18n.t('welcome.website')}
              name="website"
              normalize={(value) => value.trim().toLowerCase()}
              rules={[
                {
                  required: true,
                  message: i18n.t('welcome.formErrors.common.required'),
                },
                {
                  message: i18n.t('setup.company.website.errorWebFormat'),
                  pattern: REGULAR_EXPRESSIONS.DOMAIN,
                },
                {
                  validator: (_, value) =>
                    !value.startsWith('www.')
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            i18n.t('setup.company.website.errorStartWWW')
                          )
                        ),
                },
              ]}>
              <Input
                size="large"
                type="text"
                addonBefore="www."
                placeholder={i18n.t('welcome.websiteExample')}
              />
            </Form.Item>

            <Form.Item
              label={i18n.t('welcome.emailDomain')}
              name="atEmail"
              normalize={(value) => value.trim().toLowerCase()}
              rules={[
                {
                  required: true,
                  message: i18n.t('welcome.formErrors.common.required'),
                },
                {
                  message: i18n.t(
                    'welcome.formErrors.companyEmailDomain.reDoesntMatch'
                  ),
                  pattern: REGULAR_EXPRESSIONS.DOMAIN,
                },
                {
                  validator: (_, value) =>
                    !value.startsWith('www.')
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            i18n.t('setup.company.website.errorStartWWWAtEmail')
                          )
                        ),
                },
              ]}>
              <Input
                size="large"
                type="text"
                addonBefore="@"
                placeholder={i18n.t('welcome.websiteExample')}
              />
            </Form.Item>

            <div className="modal-buttons">
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="submit"
                    size="full"
                    color="bluishGrey"
                    text={
                      loading ? (
                        <SpinnerText text={i18n.t('common.loading')} />
                      ) : (
                        i18n.t('suppliersSecurity.addEdit.addButton')
                      )
                    }
                    onClick={async () => {
                      await form.validateFields();
                    }}
                    disabled={isButtonDisabled()}
                  />
                )}
              </Form.Item>

              <Button
                size="full"
                color="white"
                text={i18n.t('common.cancel')}
                onClick={() => dispatch(showPopUp(null))}
              />
            </div>
          </Form>
        </div>
      </SCAddSupplier>
    </ModalLayout>
  );
};

export default AddSupplier;
