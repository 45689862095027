import React from 'react';
import { faCirclePlus, faEye } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import useMonitoredSuppliers from '../../../hooks/monitoredSuppliers/useMonitoredSuppliers';
import SCMonitoredSuppliersGeneralVision from './MonitoredSuppliersGeneralVision.style';
import { getDateFormat, getDateInMs } from '../../../utils/format/dataFormat';
import { useTranslation } from '../../../i18n';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import EmptyCardContent from '../../molecules/EmptyCardContent/EmptyCardContent';

const MonitoredSuppliersGeneralVision = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const PAGE_SIZE = 10;

  const { monitoredSuppliers, getRiskLabel } = useMonitoredSuppliers();

  const mapperFn = (item) => {
    return {
      name: { type: 'string', value: item.name, sortValue: item.name },
      generalSecurity: {
        type: 'component',
        value: getRiskLabel(item?.overall?.score),
        sortValue: item?.overall?.score,
      },
      lastScan: {
        type: 'string',
        value: getDateFormat(item?.timestamp?.toDate()) ?? '-',
        sortValue: getDateInMs(item?.timestamp?.toDate()),
      },
      onClick: item.impersonation
        ? () => navigate(`/client/supplier-security/${item.domain}`)
        : undefined,
      isLoading: item?.overall?.score === undefined,
    };
  };

  const defaultSortDirection = 'asc';
  const sortOptions = {
    activeSortedColumnName: i18n.t(`suppliersSecurity.headers.name`),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const { currentPage, setSort, setSearch, setPage, rows, length } =
    useFilterTable(
      PAGE_SIZE,
      mapperFn,
      monitoredSuppliers,
      ['name'],
      sortOptions
    );

  const columns = [
    {
      name: i18n.t(`suppliersSecurity.headers.name`),
    },
    {
      name: i18n.t(`suppliersSecurity.headers.score`),
    },
    {
      name: i18n.t(`suppliersSecurity.headers.lastScan`),
    },
  ];

  return (
    <SCMonitoredSuppliersGeneralVision>
      <SectionCard
        headerIcon={faEye}
        headerTitle={`${i18n.t('suppliersSecurity.generalView')} ${
          length !== 0 ? `(${length})` : ''
        }`}>
        {monitoredSuppliers.length > 0 ? (
          <TableContainer
            columns={columns}
            rows={rows}
            onPageChange={(page) => setPage(page)}
            hasHorizontalScroll
            sortOptions={sortOptions}
            onChangeValue={setSearch}
            hasRowIcon
            totalCount={length}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
            customSideElements={
              <div className="MonitoredSuppliersGeneralVision_Action">
                <Button
                  onClick={() => dispatch(showPopUp('addSupplier'))}
                  color="white"
                  icon={faCirclePlus}
                  text={i18n.t('suppliersSecurity.addEdit.addTitle')}
                  size="mid"
                />
              </div>
            }
          />
        ) : (
          <EmptyCardContent
            text={i18n.t('controlPanel.supplierSecurity.stillNoSuppliers')}
            buttonIcon={faCirclePlus}
            buttonText={i18n.t(
              'controlPanel.supplierSecurity.addYourFirstSupplier'
            )}
            buttonOnClick={() => {
              dispatch(showPopUp('addSupplier'));
            }}
          />
        )}
      </SectionCard>
    </SCMonitoredSuppliersGeneralVision>
  );
};

export default MonitoredSuppliersGeneralVision;
